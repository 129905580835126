import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import './SiteMenu.css'


export interface SiteMenuProps {
  items: MenuItem[];
}

export interface MenuItem {
  childrenItems?: MenuItem[];
  text: string;
  uri: string;
  path: string;
  selected: boolean;
  component? : React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export default class SiteMenu extends Component<SiteMenuProps, any> {
  constructor(props: SiteMenuProps) {
    super(props);
  }

  render() {

    return (
      <div className="menu">
        <ContainerMenuNode items={this.props.items}/>
      </div>
    );
  }
}

class MenuNode extends Component<MenuItem, any> {
  constructor(props : MenuItem) {
    super(props);
  }

  render() {
    return [
      (
      <li key={this.props.text}  className={this.props.selected ? 'selected' : ''}>
        <Link to={this.props.uri}>{this.props.text}</Link>
      </li>
      ),
      this.props.childrenItems && this.props.childrenItems.length > 0 ? 
        <ContainerMenuNode key={this.props.text + '-container'}items={this.props.childrenItems}/> : null
    ];
  }
}


class ContainerMenuNode extends Component<{ items: MenuItem[] }, any> {
  constructor(props : { items: MenuItem[] }) {
    super(props);
  }

  render() {
    return (
      <ul>
        {this.props.items.map(item => 
          <MenuNode path={item.path} key={item.text} selected={item.selected} text={item.text} uri={item.uri} childrenItems={item.childrenItems}/>
        )}
      </ul>
    );
  }
}

