import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import App from './App';
import { LocaleContext, detectLocaleOrDefault, Locale, LocaleContextData } from './services/internationalization';


export default class AppContainer extends Component<any, {} & LocaleContextData> {
  constructor(props : any) {
    super(props);

    this.state = {
      changeLocale: this.changeLocale,
      locale: detectLocaleOrDefault(navigator)
    };
  }

  changeLocale = (locale : Locale) => {
    this.setState(() => ({
      locale: locale
    }));
  }

  render() {
    return (
      <LocaleContext.Provider value={this.state as LocaleContextData}>
        <IntlProvider locale={this.state.locale}>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </IntlProvider>
      </LocaleContext.Provider>
    );
  }
}

