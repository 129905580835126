import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import PageTitle from '../../components/PageTitle';
import { isBulgarian } from '../../services/internationalization';

export default injectIntl((props: {} & InjectedIntlProps) => {
  return (
    <div>
      <PageTitle text={isBulgarian(props) ? "Обща Дентална Медицина За Деца И Възрастни" : "General Dental Medicine for Adults and Children"} />

      <div className="content">
        {(() => {
          if (isBulgarian(props)) {
            return (
              <div>
                <h2>Профилактика на заболяванията на устната кухина</h2>
                <h3>Запечатване на фисурите на постоянните зъби на децата със силанти</h3>
                <p>Детските зъби пробиват с анотомично силно изразен релеф, незавършена минерализация и за съжаление понякога при не добри хигиенни навици на детето. За предпазване на зъбите от развитието на кариес се извършва покритие от силант на дълбоките фисури и бразди с цел предпазване от поява на кариес.</p>
                <h3>Професионална хигиена на устната кухина </h3>
                <p>Върху зъбната повърхност се отлага високо структуриран филм от органична материя, бактерии и хранителни частици (зъбна плака), които при недобра орална хигиена минерализира и се превръща в зъбен камък. Това предизвиква лош дъх, неприятно оцветяване, но и възпаление на венечните тъкани. С помощта на ултразвук тези отлагания се отстраняват и зъбната повърхност се полира с AirFlow (ускорени частици сода, които допълнително отстраняват упоритите налепи, цигарени отлагания и полират повърхността).</p>
                <h3>Изработване на шини за бруксизъм и спорт </h3>
                <p>Стискането на зъбите в статично състояние (бруксомания), както и скърцането със зъби (брукcизъм), е все по-чест проблем, особено сред младите хора и хората, подложени на прекомерен стрес. То води до изтриване на зъбните повърхности, болки в мускулите и ставите, главоболие и раздразнителност. Елемент от лечението е изработването на прозрачни шини, които възпрепядстват изтриването, да променят мускулния тонус и да прекратят патологичния рефлекс. <br/>
                  Подобни шини, но покриващи изцяло зъбите, се изработват за деца и възрастни, любители и професионалисти, практикуващи спортове с повишен риск от травми на зъбите и околозъбните тъкани.</p>
                <h2>Лечение на кариеса и усложненията му </h2>
                <p>Кариесът е най-често срещаното заболяване на зъбите. Основни фактори за неговото развитие са богатата на  въглехидрати диета, лошите хигиенни навици, генетично предразположение за неустойчиви зъбни структури. При наличие на кариес има остра болка от сладко, студено, задържа се храна, може да има промяна в цвета на отделен участък от зъба.</p>
                <p>Зъбната пулпа е мека тъкан вътре в зъба, която съдържа кръвоносни съдове и нерви. Разполага се в канал по хода на зъба, заобиколена от дентин/твърдата тъкан вътре в зъба, която поддържа емайла – външния зъбен слой/. Коронката е разположената над венеца част от зъба и съдържа пулпната камера. От нея пупата продължава в коренов канал до върха на корена, който се разполага в костта. Зъбите имат една пулпна камера, но един или повече коренови канала.</p>
                <p>Когато пулпата заболее или е наранена и не може да се възстанови, тя губи способността си да се защитава ефективно. Най-честата причина е счупен зъб, дълбок кариес, травма, като всички те позволяват бактерии и техни продукти да навлязат в пулпата и да я инфектират.</p>
                <p>Ако възпалената пулпа не се отстрани, заобикалящите зъба структури могат също да се инфектират и да се получи болка и оток. Даже да няма болка, някои субстанции, отделяни от бактериите, увреждат костта. Без необходимото лечение, може да се стигне до необходимост зъба да се есктрахира.</p>
                <p>Веднъж изваден зъб, който не бъде възстановен, предизвиква промени в най-близките до него зъби. Те могат да се наклонят към дефекта или да прорастнат, което затруднява отхапването и дъвченето и предизвиква пародонтални проблеми, тъй като затрудняват оралната хигиена.</p>
                <p>Възстановяването чрез мост или имплантат обикновено е по-скъпо от ендодонтското лечение и обхваща по-сериозни дентални процедури върху съседните зъби.Естествените зъби винаги са по-добри от изкуствените.</p>
                <h2>Екстракция на зъби</h2>
                <h2>Възстановителна дентална медицина</h2>
                <h2>Протезиране</h2>
                <h3>Фиксирано протезиране</h3>
                <p>Това са възстановявания, които заместват един или повече липсващи зъби, като се циментират от стоматолога върху съседните на дефекта зъби.</p>
                <p>Това помага да запазите външния вид, здравето и функцията на дъвкателния апарат, както и да се запази естествената форма на лицето, поддържайки устни и бузи в нормалната им позиция.</p>
                <p>Обикновено се циментират или бондват към естествените зъби до дефекта. Изкуственият зъб (мостово тяло) замества липсващия и възстановява функцията. В определени случаи може да се използват и композитно-свързани Мериленд крепители и директни адхезивни мостове, които не изискват екстензивно изпиляване.</p>
                <p>Различните видове неснемаеми протези се изработват от благородни и неблагородни метали, керамика и металокерамика</p>
                <p>Предимства:</p>
                <ul>
                  <li>Изглеждат, усещат се и фунционират като естествените Ви зъби</li>
                  <li>Не е необходимо да се свалят от пациента за почистване</li>
                </ul>
                <p>Недостатъци:</p>
                <ul>
                  <li>По-скъпи от снемаемите протези</li>
                  <li>Изискват изпиляване на съседните зъби</li>
                </ul>
                <h3>Снемаемо протезиране</h3>
                <p>Представляват протезни конструкции, които трябва да се свалят от пациента за да бъдат почистени. По-евтини, но определено по-неудобни и нестабилни от неснемаемите протези.</p>
                <p>Снемаемите протези имат заместващи зъби върху имитираща венеца част. Някои видове имат и метален скелет, който може да се закачва със стави или други специални елементи към неснемаеми възстановявания.</p>
                <p>Изисква се известно време да свикнете да поставяте снемаемата си протеза. Отначало тя ще се усеща странно, но постепенно ще свикнете с нея.</p>
                <p>Протезата значително ще подобри ефикасността Ви на хранене и вашият говор.</p>
                <p>Предимства:</p>
                <ul>
                  <li>Обикновено са по-евини</li>
                  <li>Обикновено са по-лесни за поправка</li>
                </ul>
                <p>Недостатъци:</p>
                <ul>
                  <li>Могат да са по-нестабилни от алтернативно неснемаемо протезиране</li>
                  <li>Могат да се счупят или загубят</li>
                  <li>Някои хора ги намират за неудобни</li>
                  <li>Някои хора се притесняват от тях</li>
                </ul>
                <h4>Плакови протези</h4>
                <h4>Протези върху телескопи и траверси</h4>
                <h4>Моделно ляти и фрезовани протези</h4>
                <h4>Еластични Valplast протези</h4>
                <h4>Назъбни протези</h4>
                <h4>Тотални протези</h4>

              </div>
            );
          } else {
            return (
              <div>
                <h2>Disease prevention in the oral cavity/Prophylaxis</h2>
                <h3>Sealing of fissures of permanent teeth of children with sealants</h3>
                <p>Milk teeth erupt with anatomically much expressed topography, unfinished mineralization and unfortunately sometimes not good hygiene habits of the child. In order to protect teeth against caries sealant coverage of those furrows should be carried out.</p>
                <h3>Professional oral hygiene</h3>
                <p>On tooth surface a highly structured film of organic matter, bacteria and food particles (dental plaque) is normally created, which in poor oral hygiene transforms into tartar. This causes bad breath, unpleasant coloring and inflammation of gingival tissues. Ultrasonic cleaning of these deposits is to be performed and tooth surface is polished with AirFlow (accelerated particles of baking soda, which remove persistent coloring, cigarette deposits and polish the surface).</p>
                <h3>Splint for bruxism and sport</h3>
                <p>Clenching of teeth in static condition (bruxomania), as well as grinding of teeth (bruxism), is increasing problem, especially among young people and people subject to an excessive stress. It leads to erasure of dental surfaces, pains in muscles and joints, headache and irritability. Element from the treatment is making of a transparent splint, which prevents erasure, changes the muscle tone and ceases of the pathological reflex. <br/>
                  Similar splints only covering entire teeth are made for children and adults, amateurs and professionals, practicing sports with increased risk of injuries of teeth and surrounding tissues.</p>
                <h2>Treatment of Caries and its Complications</h2>
                <p>Caries is the most frequent of all teeth diseases. Main contributing factors for its development are the rich carbohydrate diet, poor hygiene habits and genetic predisposition for susceptible dental structures. In the presence of caries there is acute pain when consuming cold food and beverages hold of food in the contact zone and there may be a change in color of certain area of the tooth.</p>
                <p>The pulp is soft tissue inside the tooth, which contains blood vessels and nerves. It is located in a canal inside the tooth, surrounded by dentine /hard tissue inside in the tooth, which supports the enamel –the outer tooth layer/. The crown is the upper part of the tooth and contains the pulp chamber. The pulp chamber continues in a tooth canal to the top of root, which is located in the jawbone. Teeth have one pulp chamber but one or more root canals.</p>
                <p>When pulp is infected or is damaged and may not be healed, it loses its ability to defend effectively. Most frequent cause is broken tooth, deep caries, trauma, as all they allow bacteria and their products to enter the pulp and to infect it.</p>
                <p>If the inflamed pulp is not removed, the surrounding structures may also be infected and become painful and swollen. Even if there is no pain, certain substances emitted from bacteria harm the bone. Without proper treatment all this may lead to tooth extraction.</p>
                <p>Once tooth is extracted but not restored, changes in the closest teeth occur. They may tip towards the defect or extrude, which makes it difficult to bite and chew normally and cause periodontal problems because of the hampering of the oral hygiene.</p>
                <p>The restoring of the defect with bridge or implant is generally more expensive than endodontic treatment and covers more serious dental procedures on the neighboring teeth. Natural teeth always are better than artificial.</p>
                <h2>Extraction of Teeth</h2>
                <h2>Prosthetic Dental Medicine</h2>
                <h2>Dentures</h2>
                <h2>Fixed Dentures</h2>
                <p>These are restorations which are to replace one or more missing teeth, cemented by the dentist on neighboring of the defect teeth.</p>
                <p>This helps to keep your appearance, health and the function of tooth apparatus, as well as to preserve natural appearance of the person by maintaining lips and cheek in their normal position.</p>
                <p>Bridges are usually cemented or bonded to natural teeth surrounding the defect. Artificial tooth (bridge body) replaces the missing tooth and restores function. In certain cases composite bonded Maryland fixation and direct adhesives bridges may be used, which do not require extensive tooth structure loss on the neighboring tooth.</p>
                <p>Various types of fixed prostheses are made of precious and base metals, ceramics and metaloceramics.</p>
                <p>Advantages:</p>
                <ul>
                  <li>Appear to be, feel and conduct as your natural teeth</li>
                  <li>Do not need to be removed from the patient for cleaning</li>
                </ul>
                <p>Disadvantages:<br/>
                </p><ul>
                  <li>More expensive than removable prostheses</li>
                  <li>Require grinding on the neighboring teeth</li>
                </ul>
                <p></p>
                <h3>Removable Dentures</h3>
                <p>These are dentures that could and should be removed from the patient in order to be cleaned. They are cheaper but definitely more bulky and unstable by the fixed dentures.</p>
                <p>Removable dentures have replacement teeth placed on imitation gum part. Some species has   metal skeleton, which may be connected by joints or other special elements to a fixed part.</p>
                <p>They required some time for adaptation and getting used to placing its removable denture. Initially it may be felt in a strange way but gradually you will get used to it.</p>
                <p>The denture will significantly improve the efficiency of your chewing and your speech.</p>
                <p>Advantages:</p>
                <ul>
                  <li>Generally cheaper</li>
                  <li>Usually easier for repair</li>
                </ul>
                <p>Disadvantages:</p>
                <ul>
                  <li>May be more unstable than alternative fixed dentures</li>
                  <li>May break or lose</li>
                  <li>Some people find them too bulky</li>
                  <li>Some people are shamed to wear them</li>
                </ul>
                <h4>Plain  dentures</h4>
                <h4>Dentures on telescopes and special anchorage devises</h4>
                <h4>Metal partial dentures</h4>
                <h4>Elastic Valplast prostheses</h4>
                <h4>Overdentures</h4>
                <h4>Complete dentures</h4>

              </div>
            );
          }
        })()}
      </div>
    </div>
  );
});