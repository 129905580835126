import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import PageTitle from '../../components/PageTitle';
import { isBulgarian } from '../../services/internationalization';

export default injectIntl((props: {} & InjectedIntlProps) => {
  return (
    <div>
      <PageTitle text={isBulgarian(props) ? "Естетична Дентална Медицина" : "Aesthetic Dental Medicine"} />

      <div className="content">
        {(() => {
          if (isBulgarian(props)) {
            return (
              <div>
                <h2>Бяла Eстетика</h2>
                <h3>Порцеланови и композитни инлеи, онлеи, оверлеи, фасети </h3>
                <p>При голямо количество липсващи зъбни тъкани се изработва обтурация в зъботехническа лаборатория по предварително взет отпечатък. Когато са засегнати стени и туберкули на зъба се изработва inlay или overlay – той покрива цели зъбни повърхности и допълнително укрепва останалите структури. Фасетите се използват за корекция на зъби с неестетичен цвят, форма и/или размер, както и за компенсиране на малки разстояния между зъбите.</p>
                <h2>Червена Eстетика</h2>
                <p>Понякога се наблюдава неприемлив естетично и функционално контур на гингвалните (венечните) тъкани. Той може да се дължи на екстракция на зъби, на пародонтални заболявания, на прием на на някои медикаменти (антихипертензивни, антиепилептични, имуносупресанти и други), както и да бъде генетично зададен (така нарачената гингивална усмивка). В тези случаи се прилага пародонтална хирургия (изрязване, оформяне и присаждане на тъкани).</p>
                <h2>Избелване на зъби </h2>
                <p>Консумацията на кафе, чай, червени вина, храни съдържащи оцветители, високото съдържание на флуор в питейните води на някои географски области, приемът на някои медикаменти (тетрациклини в ранна детска възраст напр.), проведено ендодонтско лечение с пълнеж влияещ върху цвета на зъба, травми с хеморагия в зъбните тъкани водят до появата на неестетично оцветяване на зъбните структури.</p>
                <h3>Домашно</h3>
                <p>След провеждане на професионална орална хигиена и лечение на засегнатите от кариес зъби се изработват прозрачни шини, в които се поставя избелващ агент. Шините се носят от пациента за определен период от време, предимно нощем, до постигане на желания резултат.</p>
                <h3>Професионално </h3>
                <p>След провеждане на професионална орална хигиена и лечение на засегнатите от кариес зъби, в кабинета се изолират зъбите, подлежащи на избелване; върху тях се прилага избелващ агент и се активира чрез топлина и/или светлина. Резултатът настъпва значително по-бързо от домашното избелване, но е свързан с по-голяма постоперативна чувствителност. В рамките на няколко посещения може да се постигне избелване от няколко степени по скалата на зъбната разцветка.</p>
              </div>
            );
          } else {
            return (
              <div>
                <h2>White Aesthetics</h2>
                <h3>Porcelain and composite inlay, оnlay, overlay, veneers</h3>
                <p>When a large quantity of dental tissues is missing a filling made in laboratory according to a in advance taken footprint should be designed. If dental walls and tubercles are affected, an inlay or overlay is made – it covers whole dental surfaces and further strengthens the remaining structures. Veneers are used for correction of teeth with unaesthetic color, shape and/or size, as well as to compensate for small distances between teeth.</p>
                <h2>Red Aesthetics</h2>
                <p>Sometimes unacceptable aesthetically and functionally contour of the gingival tissues is present. It may be due to the extraction of teeth, periodontal diseases or because of use of certain medicines (anti-hypertensive, anti-epilepsy, immunosuppressive and other), as well as to be genetically programmed (gingival smile). In these cases periodontal surgery is to be considered (excision, shaping and grafting of tissues).</p>
                <h2>Bleaching of Teeth</h2>
                <p>Consumption of coffee, tea, red wine, food containing colors, and the high fluorine content in drinking water of certain geographical areas, use of certain medicines (tetracycline in early childhood e.g. ), previous endodontic treatment with canal filling materials influencing on the tooth color, injuries with hemorrhage in dental tissues lead to the emergence of unaesthetic coloration of dental structures.</p>
                <h3>Home Bleaching</h3>
                <p>After the conduct of a professional oral hygiene and treatment of all affected by caries teeth, a transparent splint is made, in which bleaching agent shall be placed. Splint are worn by the patient for a period of time, mainly nighttime, to achieve the desired result.</p>
                <h3>Professional Bleaching </h3>
                <p>After the conduct of a professional oral hygiene and treatment of all affected by caries teeth, in the dental office the dentist isolates the teeth subjected to bleaching; the bleaching agent is applied and then activated by heat and/or light. The result occurs significantly faster than in home bleaching method but is connected with greater postoperative sensitivity. Within the framework of several visits bleaching of several grades of magnitude on the scale of tooth shades can be achieved.</p>
              </div>
            );
          }
        })()}
      </div>
    </div>
  );
});