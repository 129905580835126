import React, { FunctionComponent } from 'react';

import "./PageTitle.css";

const pageTitle : FunctionComponent<{text: string}> = (props) => {
    return(
        <div className="title">
          {props.text}
        </div>
    );
};

export default pageTitle;