import { InjectedIntlProps } from 'react-intl';
import React from 'react';

export enum Locale {
  BG = "bg",
  EN = "en"
};

const supportedLanguages = [Locale.BG, Locale.EN];
export const defaultLocale = Locale.BG;

export function isBulgarian(props: InjectedIntlProps) {
  return props.intl.locale === Locale.BG;
}

export function getLocale (props: InjectedIntlProps) : string {
  return props.intl.locale;
}

export function detectLocaleOrDefault(navigator: Navigator): Locale {

  let detectedLanguage;

  // Try 0: url
  // - this is horrible, but I can't figure out yet how to workaround the chicken and egg situation 
  //   where I need to wrap everyting in React Router to get access to the language, so for now it will do
  //
  if (window.location) {
    var rx = /\/(.{2,2})(\/|$)/;
    var matches = rx.exec(window.location.pathname);
    if (matches && matches[1]) {
      const urlLanguage = matches[1].toLowerCase();
      const found = supportedLanguages.find(supported => supported === urlLanguage);
      if (found)
        return found;
    }
  } 
  // Try 1: navigator.languages
  if (navigator.languages && navigator.languages.length > 0) {
    const supportedBrowserLanguages = navigator.languages.map(browserLang => supportedLanguages.find(supportedLang => supportedLang === browserLang.substring(0, 2).toLowerCase()));
    if (supportedBrowserLanguages.length > 0) {
      detectedLanguage = supportedBrowserLanguages[0];
      if (detectedLanguage)
        return detectedLanguage;
    }
  }

  // Try 2:
  if (navigator.language) {
    detectedLanguage = supportedLanguages.find(supportedLang => supportedLang === navigator.language.substring(0, 2).toLowerCase())
    if (detectedLanguage)
      return detectedLanguage;
  }

  return defaultLocale;
}

export interface LocaleContextData extends LocaleProps {
  changeLocale(locale: Locale) : void;
}

export interface LocaleProps {
  locale : string
}

export const LocaleContext = React.createContext({
  locale: defaultLocale,
  changeLocale: (locale: Locale) => {}
} as LocaleContextData);