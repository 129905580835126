import React from 'react';

import "./Header.css";
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { isBulgarian, Locale, LocaleContext } from '../../services/internationalization';

import flagUK from './images/flag-uk.png';
import flagBG from './images/flag-bg.png';

export default withRouter(injectIntl((props : RouteComponentProps & InjectedIntlProps) => {
  let addressText;
  if (isBulgarian(props)) {
    addressText = 
      <Link id="mapLink" to="/contactus/map">
        Телефон: 028650410  <br/>
        Мобилен: 0898680358 <br/><br/>

        бул. Христо Смирненски 23 <br/>
        ж.к. Лозенец, София
      </Link>;
  } else {
    addressText = 
      <Link id="mapLink" to="/contactus/map">
        Phone: +35928650410 <br/>
        Mobile: +359898680358 <br/><br/>

        bul Hristo Smirnenski 23 <br/>
        Sofia, Bulgaria
      </Link>;
  }
  return (
    <div id="header">
      <div id="banner-container">
        <div id="banner">
          <div id="address-container">
            {addressText}
          </div>
          <div id="home-link-overlay">
            <a id="A1" href="/"></a>
          </div>
          <LocaleContext.Consumer>
            {
              ({locale, changeLocale}) => (
                <div id="language-bar">
                  <ul>
                    <li>
                      <a onClick={() => changeLocale(Locale.EN)} href="/en">
                        <img src={flagUK}/>
                        English
                        </a>
                    </li>
                    <li>
                      <a onClick={() => changeLocale(Locale.BG)} href="/bg">
                        <img src={flagBG} />
                        Български
                      </a>
                    </li>
                  </ul>
                </div>
              )
          }
          </LocaleContext.Consumer>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
}));