import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import PageTitle from '../../components/PageTitle';

import teamPhoto from './images/team.jpg';
import { isBulgarian } from '../../services/internationalization';



export default injectIntl((props: {} &  InjectedIntlProps) => {
  return (
    <div>
      <PageTitle text={isBulgarian(props) ? "Добре Дошли" : "Welcome"}/>
      <img src={teamPhoto} alt="" style={{ margin: '0px', borderWidth: '0px', width: '690px'}} />
    </div>
  );
});