import React from 'react';

import "./Footer.css";

export default () => {
    return(
        <div id="footer">
            <span>All Rights Reserved - Diva-R EOOD</span>
            <div id="meta" style={{color: 'white'}}>
              стоматолог, стоматология, соматологичен, стоматологична клиника, стоматологичен кабинет, зъболекар, кариес, 
            </div>
        </div>
    );
};