import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import PageTitle from '../../components/PageTitle';
import { isBulgarian } from '../../services/internationalization';

export default injectIntl((props: {} & InjectedIntlProps) => {
  return (
    <div>
      <PageTitle text={isBulgarian(props) ? "Имплантология" : "Implantology"} />

      <div className="content">
        {(() => {
          if (isBulgarian(props)) {
            return (
                <div>
                    <p>Поставяне на имплантати на мястото на липсващи зъби.</p>
                    <h2>Имплантати</h2>
                    <p>Едно от най-големите постижения в денталната медицина през последните 40 години е развитието на денталните имплантати. Те са медицински устройства, които заместват кореновата част на липсващите естествени зъби. Ако естествен зъб бъде загубен в резултат на инцидент, нараняване или заболяване, имплантатът е добро и ефективно решение.</p>
                    <p>Денталният имплантат представлява здрав коничен щифт, който се поставя под венеца в коста на челюстта. Част от имплантата се простира над венеца и служи като опора за един или повече изкуствени зъба. При повечето стандартни дентални имплантати има втора част – надстройка (абатмънт), който се свързва с импланта за да поддържа заместващите се зъби.</p>
                    <p>Денталният имплантат може да поддържа един или повече зъба. Тъй като те са здраво свързани с костта, имплантат-поддържаните изкуствени зъби осигуряват визия, усещане и функция подобна на естествените.</p>
                    <p>В много случаи денталните имплантати се поставят в костта без хирургичен разрез на венеца. С други думи, могат да се поставят директно през венеца в костта в едно посещение.</p>
                    <p>По принцип единтствената необходима анестезия е локалната. </p>
                    <p>При някои пациенти може да е необходима предварителна подготовка преди поставянето на имплантатите. Вашият дентален лекар ще изработи лечебен план, който е подходящ за Вас.</p>
                    <p>Предимства:<br/>
                    </p><ul>
                        <li>Въстановеният зъб изглежда максимално близо до естествените зъби</li>
                        <li>Не се засягат съседните зъби </li>
                        <li>Намаляват или значително редуцират костната загуба след екстракция</li>
                    </ul>
                    <p></p>
                    <p>Недостатъци:<br/>
                    </p><ul>
                        <li>Не са подходящи за всекиго</li>
                        <li>Отнемат време и може да изискват повече дентални визити спрямо алтернативно лечение на дефекта</li>
                    </ul>
                    <p></p>
                    <p>Също както и естествените зъби, имплантатите могат да бъдат засегнати от износване, скъсване, травматична захапка или скърцане, остеопороза, лоша орална хигиена, тутюнопушене и други. Ето защо е особено важно да не пропускате контролните и профилактични прегледи, препоръчани Ви от Вашия дентален лекар.</p>
                    <p>Заместването на липсващите зъби може да има положително влияние на качеството на живот. Това е поради факта, че усмивката, дъвченето и разговорът са много по-лесни с пълен набор от зъби.</p>

                </div>
                );
          } else {
            return (
                <div>
                    <p>Placing of implants to restore missing teeth.</p>
                    <h2>Implants</h2>
                    <p>One of the biggest achievements in contemporary medicine over the past 40 years is the development of implants. They are medical devices, which replace the part of the missing natural teeth. If natural tooth is lost as a result of an accident, injury or illness, the implant is a good and efficient decision.</p>
                    <p>The dental implant is a strong conic post, which is placed in the bone of the jaw. Part of the implant extends over the gum and serves as a support structure for one or more artificial tooth. In most standard dental implants a second part – superstructure (abutment) is connected with the implant to maintain the replacement teeth.</p>
                    <p>The dental implant is capable of maintaining one or more teeth. Since they are tightly linked to the bone, implant-maintained artificial teeth provide vision, feeling and function similar to the natural.</p>
                    <p>In many cases implants are placed in the bone without surgical cut through the gum. In other words, implants can be placed directly through the gum in the bone in one visit.</p>
                    <p>In general the only required anesthesia is local anesthesia. </p>
                    <p>In some patients some special preparation may be needed prior the placing of the implant. Your dental doctor will create a treatment plan which is suitable for you.</p>
                    <p>Advantages:</p>
                    <ul>
                        <li>Artificial tooth appear to be as close as possible as your natural teeth</li>
                        <li>Do not affect neighboring teeth</li>
                        <li>Significantly reduce relative bone loss after extraction</li>
                    </ul>
                    <p>Disadvantages:</p>
                    <ul>
                        <li>Not suitable for everybody</li>
                        <li>Require time and more dental visits may be necessary compared to alternative treatment of the defect</li>
                    </ul>
                    <p>Just like natural teeth, the implants may be affected by wear and tear, breaking, traumatic bite or grind, osteoporosis, poor oral hygiene, smoking and other. Therefore, it is especially important not to miss control and prophylactic examinations recommended to you from your dental doctor.</p>
                    <p>The substitution of the missing teeth may have a positive influence on the quality of your life. This is due to the fact that smile, mastication and the conversation are much easier with a full set of teeth.</p>

                </div>

            );
          }
        })()}
      </div>
    </div>
  );
});