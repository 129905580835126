import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import ImageGallery from 'react-image-gallery';
import PageTitle from '../../components/PageTitle';
import { isBulgarian } from '../../services/internationalization';

import image1 from './images/1ekip.jpg';
import image1t from './images/thumbs/1ekip.jpg';
import image2 from './images/kabinet1.jpg';
import image2t from './images/thumbs/kabinet1.jpg';
import image3 from './images/kabinet2.jpg';
import image3t from './images/thumbs/kabinet2.jpg';
import image4 from './images/office.jpg';
import image4t from './images/thumbs/office.jpg';
import image5 from './images/recepciq.jpg';
import image5t from './images/thumbs/recepciq.jpg';

import "react-image-gallery/styles/css/image-gallery.css"

const images = [
  {
    original: image1,
    thumbnail: image1t,
  },
  {
    original: image2,
    thumbnail: image2t,
  },
  {
    original: image3,
    thumbnail: image3t,
  },
  {
    original: image4,
    thumbnail: image4t,
  },
  {
    original: image5,
    thumbnail: image5t,
  }
];

export default injectIntl((props: {} & InjectedIntlProps) => {
  return (
    <div>
      <PageTitle text={isBulgarian(props) ? "Галерия" : "Gallery"} />

      <div className="content">
        <ImageGallery items={images} />

      </div>
    </div>
  );
});