import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import PageTitle from '../../components/PageTitle';

import { isBulgarian } from '../../services/internationalization';

import busIcon from './images/icon-bus.gif';
import tramIcon from './images/icon-tram.gif';
import mapImage from './images/map.png';
import metroIcon from './images/icon-metro.gif';

const googleMapsUrl = "https://goo.gl/maps/FLv1cNeHmXm2ruf79";

const showLargeMapOnClick = () => {
  window.open (googleMapsUrl);
  return false;
}
export default injectIntl((props: {} & InjectedIntlProps) => {
  return (
  <div>
    <PageTitle text={isBulgarian(props) ? "За Връзка" : "Contact Us"} />

    <div className="content">
      <table cellPadding={3} style={{border: 0, width: '100%'}}>
        <tr>
          <th align="left" style={{textDecoration: 'underline'}}>
           { isBulgarian(props) ? "За Връзка" : "Contact Us"}
          </th>
          <th align="left" style={{textDecoration: 'underline'}}>
           { isBulgarian(props) ? "Aдрес" : "Address"}  
          </th>
          <th align="left" style={{textDecoration: 'underline'}}>
            { isBulgarian(props) ? "Работно време" : "Working hours"}
          </th>
        </tr>
        <tr>
          <td>
            <i>
              { isBulgarian(props) ? "Tелефон" : "Phone Number"}
              : 028650410
              <br />
              { isBulgarian(props) ? "Мобилен" : "Mobile"}
              : 0898680358
              <br />
            </i>Email: <a href="mailto:contact@diva-r.com">contact@diva-r.com</a>
          </td>
          <td>
            { isBulgarian(props) ? "бул. Христо Смирненски 23" : "bul Hristo Smirnenski 23"}
            <br/>
            { isBulgarian(props) ? "ж.к. Лозенец" : "Lozenetz"}
            <br/>
            { isBulgarian(props) ? "София 1164" : "Sofia 1164"}
          </td>
          <td>
            { isBulgarian(props) ? "Понеделник - Петък" : "Monday - Friday"}
            <br/>
            { isBulgarian(props) ? "08:00 - 19:00" : "08:00 - 19:00"}
          </td>
        </tr>
      </table>


      <table className="no-border no-spacing" cellPadding="4px">
        <tr>
            <td className="center-content">
                <img id="Image1" className="no-border no-spacing" src={busIcon} alt="" />
            </td>
            <td>
                2, 9, 10, 12, 18
            </td>
        </tr>
        <tr>
            <td className="center-content">
                <img id="Img2" className="no-border no-spacing"src={tramIcon} alt="" />
            </td>
            <td>
                94, 102
            </td>
        </tr>
        <tr>
            <td className="center-content">
                <img id="Img1" className="no-border no-spacing" src={metroIcon} alt="" />
            </td>
            <td>
              {isBulgarian(props) ? "Стадион Васил Левски" : "Vasil Levksi Stadium"}
            </td>
        </tr>
      </table>

      <div style={{ textAlign: 'right', marginLeft: 'auto', marginRight: 'auto' }}>
        <a href="" onClick={showLargeMapOnClick} style={{ textAlign: 'left' }} >
            {isBulgarian(props) ? "Виж по-голяма карта" : "See larger map"}
        </a>
        <br />
      </div>
      <a href={googleMapsUrl}>
        <img src={mapImage} alt="" style={{width: "680px"}}/>
      </a>
    </div>
  </div>
  )
});