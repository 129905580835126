import React, { Component } from 'react';
import './App.css';
import SiteMenu, { MenuItem } from './components/SiteMenu';
import Footer from './components/Footer';
import Header from './components/Header/Header';
import HomePage from './pages/Home';
import TeamPage from './pages/Team';
import ContactUsPage from './pages/ContactUs';
import ServicesPage from './pages/Services';
import AestheticsPage from './pages/Services/aesthetetic';
import GeneralPage from './pages/Services/general';
import PeriodontologyPage from './pages/Services/periodontology';
import ImplantologyPage from './pages/Services/implantology';
import GalleryPage from './pages/Gallery';

import { Route, RouteComponentProps, withRouter, Switch } from 'react-router-dom';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { getLocale, isBulgarian } from './services/internationalization';

interface AppProps extends RouteComponentProps, InjectedIntlProps {}

class App extends Component<AppProps> {
  createRoute(locale: string, text: string, uri: string, component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>, 
              children?: MenuItem[]) : MenuItem {
    const item = {
      text: text,
      uri: "/" + locale + uri,
      path: "/:lang" + uri,
      selected: this.props.location.pathname.includes(uri),
      childrenItems: children,
      component: component
    };
    return item;
  }

  componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<{}>, snapshot?: any): void {
    console.log("routing params:" + JSON.stringify(this.props.match));
  }

  render() {
    let language = getLocale(this.props);
    const routes : MenuItem[] = [
      this.createRoute(language, isBulgarian(this.props) ? 'Начало' : 'Home', '/', HomePage),
      this.createRoute(language, isBulgarian(this.props) ? 'Услуги' : 'Services', '/services', ServicesPage, [
        this.createRoute(language, isBulgarian(this.props) ? 'Естетична Дентална Медицина' : 'Aesthetic Dental Medicine', '/services/aesthetic', AestheticsPage),
        this.createRoute(language, isBulgarian(this.props) ? 'Обща Дентална Медицина' : 'General Dental Medicine', '/services/general', GeneralPage),
        this.createRoute(language, isBulgarian(this.props) ? 'Парoдонтология' : 'Periodontology', '/services/periodontology', PeriodontologyPage),
        this.createRoute(language, isBulgarian(this.props) ? 'Имплантология' : 'Implantology', '/services/implantology', ImplantologyPage),
      ]),
      this.createRoute(language, isBulgarian(this.props) ? 'Галерия' : 'Gallery', '/gallery', GalleryPage),
      this.createRoute(language, isBulgarian(this.props) ? 'Екип' : 'The Team', '/team', TeamPage),
      // this.createRoute(language, isBulgarian(this.props) ? 'Новини' : 'News', '/news'),
      this.createRoute(language, isBulgarian(this.props) ? 'За Връзка' : 'Contact us', '/contactus', ContactUsPage, [
        this.createRoute(language, isBulgarian(this.props) ? 'Карта' : 'Map', '/contactus/map', ContactUsPage),
      ]),
    ];

    return (
      <div>
        <div className="page">
          <Header />
          <div className="bottom-shadow">
            <div className="left-shadow">
              <div className="right-shadow">
                <div id="page-container">
                  <div id="menucontainer">
                    <SiteMenu items={routes} />
                  </div>
                  <div id="content-container">
                    <Switch>
                      {
                        routes
                          .map(route => route.childrenItems ? [route, ...route.childrenItems]: [route])
                          .flat()
                          .map(route => (
                            <Route exact path={route.path} component={route.component} key={route.uri}/>
                        ))
                      }
                      <Route path="/" component={HomePage} />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(injectIntl(App));
