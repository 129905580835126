import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import PageTitle from '../../components/PageTitle';
import { isBulgarian } from '../../services/internationalization';

export default injectIntl((props: {} & InjectedIntlProps) => {
  return (
    <div>
      <PageTitle text={isBulgarian(props) ? "Парадонтология" : "Periodontology"} />

      <div className="content">
        {(() => {
          if (isBulgarian(props)) {
            return (
                <div>
                    <h2>Лечение на пародонталните заболявания (на венеца и подлежащата кост)</h2>
                    <p>Пародонталното лечение зависи от типа и тежестта на инфекцията. Ако заболяването е хванато рано – гингивит (възпаление на венеца), и няма необратими промени, достатъчни са инструкции за подобряване на оралната хигиена.</p>
                    <p>Дори с тези мерки, някои пациенти развиват по-тежки форми на заболяване, нуждаещи се от лечение. Първата стъпка включва почистване с ултразвук (скалинг), и заглаждане на кореновата повърхност – планинг. Тези процедури премахват плаката и зъбния камък от зъбната повърхност, помагат на оздравителния процес и свиването на джобовете, което от своя страна пречи на повторното им натрупване. Понякога тези процедури могат да отнемат повече от едно посещение.</p>
                    <p>Вашият стоматолог може да Ви препоръча медикамент, който да контролира инфекцията и болката и да помогне на оздравяването. Това може да са таблетки, разтвори за жабурене, субстанции, които се поставят директно във венечния джоб. Може да се наложи консултация и котрол на системно заболяване.</p>
                    <p>Когато се образуват дълбоки джобове, е трудно да се отстрани изцяло плаката и зъбния камък дори с щателна орална хигиена. Ако джобовете не се възстановяват след стандартното почистване, може да е необходима пародонтална хирургия за почистването и редуцирането им. </p>
                    <h2>Пародонтални заболявания</h2>
                    <p>Думата “пародонтален” означава около зъба.Здравите гингивални (венечни) тъкани обхващат като ръкавица зъба.Там където линията на венеца среща зъба се оформя лека V-образна бразда, наречена гингивален (венечен) сулкус. При здравите зъби той е дълбок 3мм или по-малко.<br/>
                        Пародонталните заболявания са инфекции, които засягат тъканите, поддържащи зъба. Когато тъканите са увредени, сулкусът се превръща в джоб по-дълбок от 3мм. По принцип колкото е по-тежко възпалението, толкова е по-дълбок джоба и е по-голяма загубата на кост.</p>
                    <p>Уголемените джобове позволяват растежа на патогенни бактерии и затрудняват ефективната орална хигиена. Оставени без лечение, пародонталните заболявания могат да доведат до загуба на зъби.</p>
                    <p>Възможно е да страдате от пародонтално заболяване без да го осъзнавате.Затова редовните профилактични визити и пародонтални прегледи са особено важни.</p>
                    <p>Някои алармиращи признаци са:</p>
                    <ul>
                        <li>Лесно кървящи венци</li>
                        <li>Зачервени, подути или болезнени венци</li>
                        <li>Отдръпнати от зъбите венци</li>
                        <li>Упорит лош дъх</li>
                        <li>Гной между зъбите и венците</li>
                        <li>Подвижни или раздалечаващи се зъби</li>
                        <li>Промяна в начина  на контакт между зъбите при захапка</li>
                        <li>Промяна в пасването на частични протези</li>
                    </ul>
                    <p>Устната кухина съдържа огромен брой бактерии. Пародонталните заболявания започват, когато определине бактерии от плаката /лепкав безцветен филм, който постоянно се образува върху зъбите и повърхностите в устната кухина/, произвеждат токсини и ензими, които дразнят гингивалните тъкани и предизвикват възпаление. То може да е безболезнено и да увреди прикрепването на венеца и подлежащата кост.</p>
                    <p>Добрата орална хигиена – четкането на зъбите 2 пъти на ден и употребата на конец, интердентална четка или друго хигиенично средство за почистване на междузъбните пространства – помага за разрушаване на плаковия филм. Плаката, коята не се отстранява редовно, втвърдява във вид на твърди порьозни отлагания – зъбен камък. Той не е основната причина за пародонталните заболявания, но порите му задържат токсини и бактерии, които не могат да се отстранят дори при редовното четкане на зъбите. След образуването на зъбния камък, той може да бъде отсранен само чрез професионално почистване на зъбите в дентален кабинет.</p>
                    <p>Има няколко фактора, които повишават риска да се развие пародонтално заболяване. Вие сте с повишен риск, ако:</p>
                    <ul>
                        <li>Пушите или дъвчите тютюн</li>
                        <li>Имате системно заболяване – диабет, кръвни нарушения, ХИВ, СПИН, някои вродени заболявания – синдром на Даун, палмо-плантарна хиперкератоза и др.</li>
                        <li>Приемате някои медикаменти – стероиди, антиепилептични лекарства, онкологични, антихипертензивни, противозачатъчни.</li>
                        <li>Имате лошо пасващи неснемаеми конструкции</li>
                        <li>Хормонален дисбаланс – пубертет, бременност, орални контрацептиви</li>
                        <li>Генетична предразположеност</li>
                        <li>Пародонтални заболявания могат да се предават от родители на деца и между партньори</li>
                    </ul>
                    <h3>Гингивит</h3>
                    <p>Най-леката форма на пародонтално заболяване. Предизвиква зачервяване, оточност и лесно кървене на венците. Обикновено има лек или никакъв дискомфорт на този етап. Обратим е с професионално лечение и добра орална хигиена вкъщи.</p>
                    <h3>Хроничен пародонтит</h3>
                    <p>Хроничен пародонтит е форма на пародонтално заболяване, която се изразява във възпаление в поддържащите зъба тъкани. Пациентите имат прогресивна загуба на кост и тъканно прикрепване. Характеризира се с образуване на джобове и/или рецесия на гингивата. Това е най-често срещаната форма на пародонтално заболяване. Преобладава сред възрастните, но може да се развие на всяка възраст. Прогресията на костната загуба е бавна, но може да се проявят и периоди на бърза прогресия.</p>
                    <h3>Агресивен пародонтит</h3>
                    <p>Агресивен пародонтит е високо деструктивна форма на пародонтално заболяване, която се среща при пациенти, които са привидно клинично здрави. Общи черти са бързата загуба на тъканно прикрепване и костна деструкция. Този тип заболяване може да протече в локализирана или генерализирана форма.</p>
                    <h3>Пародонтит като манифестация на системно заболяване</h3>
                    <p>Пародонтит като манифестация на системно заболяване е пародонтално заболяване, асоциирано с някои системни заболявания, като диабет например. Пациенти с редки специфични кръвни заболявания или генетични нарушения често показват признаци на пародонтално заболяване.</p>
                    <h3>Некротизиращи форми на пародонтална инфекция</h3>
                    <p>Некротизиращи форми на пародонтална инфекция са заболявания, характеризиращи се с болка, некроза и възпаление на гингивални тъкани, пародонтален лигамент и алвеоларна кост. Тези лезии често се асоциират с болка, кървене, лош дъх. Предизпониращи фактори са емоционалният стрес, тютюнопушене и наличие на ХИВ инфекция. </p>
                    <h2>Скрининг за наличие на  пародонтално заболяване</h2>
                    <p>По време на преглед, денталният лекар преглежда и вашите венци. С пародонтална сонда внимателно се измерва дълбочината на венечния джоб около всеки зъб. При здрав джоб тя не превишава 3мм. Пародонталната сонда показва дали имате джобове и каква е тяхната дълбочина. Като цяло, колкото е по-тежко състоянието – толкова са по-дълбоки джобовете.<br/>
                        Може да се направят рентгенови снимки за да се оцени нивото на костта, поддържаща зъбите, както и да се регистрират проблеми, скрити при стандартния оглед. </p>
                    <h2>Превенция на пародонталните заболявания</h2>
                    <p>Добрата орална хигиена за няколко минути два пъти дневно помага за редуцирането на риска от развитие на пародонтално заболяване и зъбен кариес.</p>
                    <ul>
                        <li>Четкайте зъбите си два пъти дневно – с внимателното четкане може да отстраните плаката от вътрешната, външната и дъвкателната повърхност на зъбите. Вашият стоматолог може да Ви покаже подходяща техника на четкане</li>
                        <li>Почиствайте два пъти на ден зъбите с дентални конци или друго средство за интердентално почистване за да отскраните плаката и хранителните остатъци от зоните, където четката не би могла да достигне</li>
                        <li>Ако се нуждаете от допълнителна помощ за контрол на плаката, която се образува над нивото на венеца, може да Ви се препоръча антимикробна вода за уста или друго хигиенно средство като добавка към Вашия хигиенен режим</li>
                        <li>Хранете се балансирано и ограничавайте кариесогенните храни и напитки</li>
                        <li>Посещавйте своя дентален лекар редовно.Професионалното почистване на зъбите е единственият начин да се отстрани зъбния камък</li>
                    </ul>

                </div>
            );
          } else {
            return (
                <div>
                    <h2>Treatment of periodontal diseases (of gum and bone)</h2>
                    <p>The periodontal treatment depends on the type and severity of the infection. If the disease is caught early -gingivitis (inflammation of gum), and there are no irreversible changes, then it is sufficient to give out instructions for the improvement of the oral hygiene.</p>
                    <p>Even with these measures some patients develop severe forms of disease that require treatment. The first step includes cleaning with ultrasonic (scaling), and smoothening of the surface – planning. These procedures remove dental plaque and tartar from the tooth surfaces, thus preventing the recurrence of the process and facilitating the shrinkage of the gingival pockets, which in turn also prevent accumulation of dental plaque. Sometimes those procedures could take more than one visit.</p>
                    <p>Your dentist may recommend you a therapy to control the infection and pain and to help the rehabilitation. This may be tablets, solutions for mouth rinsing, substances to be placed directly in the gingival pocket. It may be necessary to consult your General Practitioner in order to control a systemic disease you may have.</p>
                    <p>When deep pockets are present, it is difficult to remove entirely dental plate and tartar even with a thorough oral hygiene. If pockets do not reduce enough after standard cleaning, it may be necessary to perform periodontal surgery for cleaning and reduction of the depth of the pathologic areas. </p>
                    <h2>Periodontal diseases</h2>
                    <p>The word “periodontal” means “around the tooth”. Healthy gingival tissues cover the tooth as a glove. At the line where the gum meets the tooth a V-shaped groove, called gingival sulcus is present. In healthy teeth it is 3мм or less deep.</p>
                    <p>Periodontal diseases are infections, which affect the tooth supporting tissues. When these tissues are damaged, the sulcus deepens more than the normal values. In general the more serious inflammation, the deeper the pockets and the greater the loss of bone.</p>
                    <p>Enlarged pockets allow growth of pathogenic bacteria and impede effective oral hygiene. Left untreated,periodontal diseases could lead to loss of teeth.<br/>
                        It is possible a periodontal disease to be present without knowing it. That is why regular prophylactic visits and periodontal examinations are very important.</p>
                    <p>Some alarm signs are:</p>
                    <ul>
                        <li>Easily bleading gums</li>
                        <li>Redish, swollen or painful gums</li>
                        <li>Retracted away from the teeth gums</li>
                        <li>Persistent bad breath</li>
                        <li>Pus between teeth and gums</li>
                        <li>Mobile or diverging teeth</li>
                        <li>Change in the way of contacts between the teeth in closure</li>
                        <li>Change in the fit of partial dentures</li>
                    </ul>
                    <p>Oral cavity contains huge number of bacteria. Periodontal diseases begin when certain bacteria from the plaque /sticky colorless film, which permanently forms on teeth and the surfaces in the oral cavity/, produce toxins and enzymes, which irritate the gingival tissues and cause inflammation. It may be painless and to harm the junction of gum and bone.</p>
                    <p>Good oral hygiene – brushing the teeth 2 times a day and the use of dental floss, interdental brush or any other hygienically means for cleaning the interdental spaces, helps for the destruction of plaque film. The plaque if not removed regularly, transforms in the form of solid porous deposits – tartar. Tartar is not the main reason for periodontal diseases but his pores retain toxins and bacteria, which may not be removed even with regular teeth brushing. After the tartar deposition, it may be removed only through professional cleaning of the teeth in dental office.</p>
                    <p>There are several factors which increase the risk to develop periodontal disease. You are with increased risk if:</p>
                    <ul>
                        <li>Smoke or chew tobacco</li>
                        <li>Have systemic disease- diabetes, blood disorders, HIV, AIDS, certain congenital diseases – Down syndrome, Palm-plantar hyperkeratosis and others.</li>
                        <li>Accept certain medicines – steroids, antiepileptic medicines, anticancer, antihypertensive, contraceptives.</li>
                        <li>Have ill-fitting fixed dentures</li>
                        <li>Hormonal imbalance – puberty, pregnancy, oral contraceptives</li>
                        <li>Genetic susceptibility</li>
                        <li>Periodontal diseases could be transmitted from parents to children and between partners</li>
                    </ul>
                    <h3>Gingivitis</h3>
                    <p>This is the lightest form of periodontal disease. It causes redness, edema and easily bleeding of gums. Normally has a slight or no discomfort at this stage. It is reversible with professional treatment and good oral hygiene at home.</p>
                    <h3>Chronic periodontitis</h3>
                    <p>Chronic periodontitis is a form of periodontal disease, which is expressed in inflammation in the tooth supporting tissues. Patients have progressive loss of bone and tissue attachment. It is characterized by formation of pockets and/or recession of the gingiva. This is the most common form of periodontal disease. Predominates among older people but could develop in any age. The bone loss progression is slow but periods of rapid progression may be presented.</p>
                    <h3>Aggressive periodontitis</h3>
                    <p>Aggressive periodontitis is a highly destructive form of periodontal disease which occurs among patients, which are apparently clinically healthy. Common features are rapid loss of tissue attachment and bone destruction. This type of disease may be seen in localized or generalized form.</p>
                    <h3>Periodontitis as a manifestation of systemic disease</h3>
                    <p>Periodontitis as a manifestation of systemic disease is periodontal disease associated with certain systemic disease, such as diabetes for example. Patients with rare specific blood diseases or genetic disorders often show signs of periodontal disease.</p>
                    <h3>Necrotizing forms of periodontal infection</h3>
                    <p>Necrotizing forms of periodontal infection are diseases characterized by pain, necrosis and inflammation of gingival tissues, periodontal ligament and alveolar bone. These lesions are often associated with pain, bleeding and bad breath. Predisposing factors are powerful stress, tobacco abuse and the presence of HIV infection. </p>
                    <h2>Screening for periodontal disease</h2>
                    <p>During the check-up the dental doctor examines your gums as well. He/she measures with periodontal probe the depth of the gingival pocket around each tooth. By the sound pocket it does not exceed 3мм. The periodontal probe indicates whether you have pockets and what is their depth. In general the serious the condition – the deeper the pockets.</p>
                    <p>The dentist may take x-ray photos in order to assess the level of the bone, capable of maintaining the teeth, as well as to register problems, hidden in the standard view. </p>
                    <h2>Prevention of periodontal diseases </h2>
                    <p>Good oral hygiene for several minutes twice a day helps for reduction of the risk of development periodontal disease and dental caries.</p>
                    <ul>
                        <li>Brush your teeth twice a day – with careful brushing you can remove plaque from internal, external and occlusal surface of the teeth. Your dentist can show you appropriate brushing technique </li>
                        <li>Clean two times a day your teeth with dental floss or other means of interproximal cleaning to remove plaque and food residues from the zones the brush would not be able to reach</li>
                        <li>If you need additional help for control of the plate, which is formed above the level of gum, you may be recommend particular solution for mouth rinse or any other hygienic means as a supplement to your sanitary arrangements</li>
                        <li>Eat balanced diet and restrain from cariesgenic foods and drinks</li>
                        <li>Visit your dental doctor regularly. Professional cleaning of the teeth is the only way to remove dental tartar</li>
                    </ul>

                </div>
            );
          }
        })()}
      </div>
    </div>
  );
});