import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import PageTitle from '../../components/PageTitle';

import { isBulgarian } from '../../services/internationalization';

import bzlatevaPhoto from './images/bzlateva-portrait.jpg';
import jbelejanskaPhoto from './images/jbelejanska-portrait.jpg';

import './team.css';

export default injectIntl((props: {} & InjectedIntlProps) => {
  return (
    <div>
      <PageTitle text={isBulgarian(props) ? "Нашият Екип" : "The Team"} />

      <div className="content">
        <div className="team-entry">
          <h3>
            {isBulgarian(props) ? "Д-р Борислава Златева (Управител)" : "Dr. Borislava Zlateva (Owner)"}
          </h3>

          <img className="portrait" alt="" src={bzlatevaPhoto} />
          <p>
            Д-р Златева завършва с отличен успех Факултета по Дентална Медицина – София през 1989. През 1997 полага изпит по специалност „Обща Стоматология”. През 1994 създава частната си практика „Дива-Р”, на която е управител и до днес. През 2009 получаваа наградата „Мениджър на Дентален Кабинет”.
          </p>
          <p>
            Избрана е за „Стоматолог на Годината 2005”. Постоянно инвестира в нови технологии и знания. Посещавала е и продължава да посещава редица квалификационни курсове по ендодонтия, имплантология, пародонтология и естетична дентална медицина в страната и чужбина. Има редица сертификати и дипломи от Германия, Франция, Италия, Австрия, Швейцария и други.
          </p>
        </div>
        <div className="clear"></div>

        <div className="team-entry">
          <h3>
            {isBulgarian(props) ? "Д-р Йоанна Бележанска" : "Dr. Joanna Belejanska"}
          </h3>

          <img className="portrait" alt="" src={jbelejanskaPhoto} />
          <p>
            Завършва като „Отличник на Випуска” Факултета по Дентална Медицина на Медицинска академия – София през 2009г.
          </p>
          <p>
            Посещава множество лекции и практически курсове за съвременна ендодонтия, естетична и козметична възстановителна дентална медицина, съвременните аспекти във фармакотерапията на денталните заболявания, комуникативно-поведенческата психотерапия.
          </p>
          <p>
            Има интереси в областта на консервативното лечение,пародонтология и хирургия.
          </p>
        </div>

        {/* <div className="clear"></div>

        <div className="team-entry">
          <h3>
            {isBulgarian(props) ? "Д-р Радина Димитрова" : "Dr. Radina Dimitrova"}
          </h3>
          <img className="portrait" src={rdimitrovaPhoto} />
          <p>
            Д-р Димитрова завършва Факултета по Дентална Медицина на Медицински университет – София през 2013.
          </p>
        </div>

        <div className="clear"></div>

        <table style={{ width: "100%", borderWidth: 0, textAlign: 'center' }}>
          <tbody>
            <tr>
              <td>
                <img className="portrait" src={dbazovichkaPhoto} />
              </td>
              <td>
                <img className="portrait" src={dkehajiovaPhoto} />
              </td>
            </tr>
            <tr>
              <td>
                <h3 style={{ marginTop: 0 }}>
                  {isBulgarian(props) ? "Дентален Асистент Даниела Базовичка" : "Assitant Daniela Bazovichka"}
                </h3>
              </td>
              <td>
                <h3 style={{ marginTop: 0 }}>
                  {isBulgarian(props) ? "Дентален Асистент Даниела Кехайова" : "Assistant Daniela Kehajova"}
                </h3>
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </div>
  );
});