import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import PageTitle from '../../components/PageTitle';

import { isBulgarian } from '../../services/internationalization';
import keramikaImage from './images/keramika.jpg';
import implantImage from './images/implant.jpg';
import periodontalImage from './images/periodontal.jpg';
import smileImage from './images/smile.jpg';
import toothDiagramImage from './images/toothdiagram.gif';
import valplastImage from './images/valplast.jpg';

import './styles.css';

export default injectIntl((props: {} & InjectedIntlProps) => {
  return (
    <div>
      <PageTitle text={isBulgarian(props) ? "Услуги" : "Services"} />

      <div className="content">
        {(() => {
          if (isBulgarian(props)) {
            return (
              <div>
                <h2><a id="aesthetic">Естетична Дентална Медицина </a></h2>
                <table style={{ width: '100%' }}>
                  <tr>
                    <td>
                      <h3>Бяла Eстетика</h3>
                      <ul>
                        <li>Порцеланови и композитни инлеи, онлеи, оверлеи, фасети</li>
                      </ul>
                      <h3>Червена Eстетика</h3>
                      <ul>
                        <li>Козметична хирургия на меките тъкани</li>
                      </ul>
                      <h3>Избелване на зъби</h3>
                      <ul>
                        <li>Домашно</li>
                        <li>Професионално</li>
                      </ul>
                    </td>
                    <td>
                      <img src={smileImage} alt="" />
                    </td>
                  </tr>
                </table>

                <hr />
                <h2>
                  <a id="general">
                    Обща Дентална Медицина за Деца и Възрастни
                  </a>
                </h2>
                <h3>Профилактика на заболяванията на устната кухина</h3>
                <ul>
                  <li>Запечатване на фисурите на постоянните зъби на децата със силанти</li>
                  <li>Професионална хигиена на устната кухина</li>
                  <li>Изработване на шини за бруксизъм и спорт</li>
                </ul>

                <h3>Лечение на кариеса и усложненията му</h3>

                <h3>Екстракция на зъби</h3>

                <h3>Възстановителна дентална медицина</h3>
                <ul>
                  <li>Неподвижно протезиране - керамични и метало-керамични възстановявания</li>
                </ul>
                <img src={keramikaImage} alt="" />

                <table>
                  <tr>
                    <td>
                      <ul>
                        <li>Подвижно протезиране
                          <ul>
                            <li>Моделно ляти и фрезовани протези</li>
                            <li>Еластични Valplast протези</li>
                            <li>Тотални протези</li>
                          </ul>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <img src={valplastImage} alt="" />
                    </td>
                  </tr>
                </table>
                <hr />

                <h2>
                  <a id="periodontology">
                    Пародонтология
                  </a>
                </h2>

                <h3>Лечение на гингивити (възпаление на венците)</h3>
                <h3>Лечение на пародонтити (възпаление и костна загуба)</h3>
                <table>
                  <tr>
                    <td>
                      <img src={periodontalImage} alt="" className="center" />
                    </td>
                    <td>
                      <img src={toothDiagramImage} alt="" />
                    </td>
                  </tr>
                </table>
                <hr />

                <h2>
                  <a id="implantology">
                    Имплантология
                  </a>
                </h2>

                <h3>Поставяне на импланти на мястото на липсващи зъби</h3>
                <img src={implantImage} alt="" />

              </div>
            );
          } else { // TODO: needs translating
            return (
              <div>
              <h2><a id="aesthetic">Естетична Дентална Медицина </a></h2>
              <table style={{ width: '100%' }}>
                <tr>
                  <td>
                    <h3>Бяла Eстетика</h3>
                    <ul>
                      <li>Порцеланови и композитни инлеи, онлеи, оверлеи, фасети</li>
                    </ul>
                    <h3>Червена Eстетика</h3>
                    <ul>
                      <li>Козметична хирургия на меките тъкани</li>
                    </ul>
                    <h3>Избелване на зъби</h3>
                    <ul>
                      <li>Домашно</li>
                      <li>Професионално</li>
                    </ul>
                  </td>
                  <td>
                    <img src={smileImage} alt="" />
                  </td>
                </tr>
              </table>

              <hr />
              <h2>
                <a id="general">
                  Обща Дентална Медицина за Деца и Възрастни
                </a>
              </h2>
              <h3>Профилактика на заболяванията на устната кухина</h3>
              <ul>
                <li>Запечатване на фисурите на постоянните зъби на децата със силанти</li>
                <li>Професионална хигиена на устната кухина</li>
                <li>Изработване на шини за бруксизъм и спорт</li>
              </ul>

              <h3>Лечение на кариеса и усложненията му</h3>

              <h3>Екстракция на зъби</h3>

              <h3>Възстановителна дентална медицина</h3>
              <ul>
                <li>Неподвижно протезиране - керамични и метало-керамични възстановявания</li>
              </ul>
              <img src={keramikaImage} alt="" />

              <table>
                <tr>
                  <td>
                    <ul>
                      <li>Подвижно протезиране
                        <ul>
                          <li>Моделно ляти и фрезовани протези</li>
                          <li>Еластични Valplast протези</li>
                          <li>Тотални протези</li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <img src={valplastImage} alt="" />
                  </td>
                </tr>
              </table>
              <hr />

              <h2>
                <a id="periodontology">
                  Пародонтология
                </a>
              </h2>

              <h3>Лечение на гингивити (възпаление на венците)</h3>
              <h3>Лечение на пародонтити (възпаление и костна загуба)</h3>
              <table>
                <tr>
                  <td>
                    <img src={periodontalImage} alt="" className="center" />
                  </td>
                  <td>
                    <img src={toothDiagramImage} alt="" />
                  </td>
                </tr>
              </table>
              <hr />

              <h2>
                <a id="implantology">
                  Имплантология
                </a>
              </h2>

              <h3>Поставяне на импланти на мястото на липсващи зъби</h3>
              <img src={implantImage} alt="" />

            </div>
            );
          }
        })()}
      </div>
    </div>
  );
});